import '@blueprintjs/core/lib/css/blueprint.css'
import { Alert, message } from 'antd'
import axios, { AxiosError } from 'axios'
import { getCookie } from 'cookies-next'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Workspace } from 'polotno/canvas/workspace'
import {
  unstable_setAnimationsEnabled,
  unstable_setTextVerticalResizeEnabled,
  unstable_useHtmlTextRender,
} from 'polotno/config'
import createStore from 'polotno/model/store'
import { PagesTimeline } from 'polotno/pages-timeline'
import { DEFAULT_SECTIONS, SidePanel } from 'polotno/side-panel'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useGetProject } from './api/useGetProject'
import { IS_FREE_PLATFORM, POLOTNO_KEY, QUICKADS_REDIRECT_URL, REDIRECT_ON } from './config'
import { useGlobalContext } from './context/globalContext'
import { CustomHistoryButtons } from './features/polotno/component/toolBar/historyButtons'
import { ResizeDropdown } from './features/resizeDropdown/resizeDropdown'
import { RightSidebar } from './features/rightSidebar/rightSidebar'
import { CustomVideoTimelineSection } from './features/video/customVideoTimeline/videoTimeline'
import { Loading } from './shared/components/loading'
import { PageLayout } from './shared/components/pageLayout'
import { ScriptToCanvasFunc } from './shared/utils/VideoGeneration/generateVideoHook'
import { handlePutImage, updateProject } from './shared/utils/core'
import { PageControls } from './features/polotno/component/workspace/pageController'
import './App.less'
import { CustomActionControlContainer } from './features/polotno/component/customPolotno/customActionControlContainer'
import { CustomAssetsSection } from './features/polotno/component/customPolotno/customAssetsSidePanel'
import { CustomVideoSearch } from './features/polotno/component/customPolotno/customVideoSearchPanel'
// import { PagesTimeline } from 'polotno/pages-timeline';
/* When you enable animations, polotno will add additional UI in the toolbar to change animation properties of the selected object.*/
import { ScriptToCanvasBurst } from './shared/utils/VideoGeneration/BurstScriptToCanvas'
import { ScriptToCanvas } from './shared/utils/VideoGeneration/ScriptToCanvas'
// import { trackEvent } from 'src/lib/mixpanel'
import IsMobileModal from './shared/components/isMobileModal'
import { customVideoVariations } from './features/video/videoVariations/videoVariations'
import { resizeDesign } from './shared/utils/polotno/resizing'
import { trackEvent } from './lib/mixpanel'
import { testScriptresponse } from './data/testOutput'
import { preloadAssets } from './shared/utils/preloadAssetsInClass'
import { useGetDomainDetails } from './api/useGetDomainDetails'
import { useGetUserDeails } from './api/useGetUserDetails'
import { generatePrimaryShades } from './shared/utils/generatePallate'
import { creditsDeduct, gettyAssetTrack, updateWaterMarkVideos } from './shared/utils/VideoGeneration/helperFunctions/mediaSearch'
import { initializeEventSource } from './shared/utils/VideoGeneration/helperFunctions/events'
import { domainLogout } from './config/config'
import { AnimationDropdown } from './features/animation/animationSelect'
unstable_setAnimationsEnabled(true)

/* By default, you can't change height of text elements. Texts are always updating its own height to fit its content. 
When vertical resize is enabled you can also set vertical align from the text toolbar.
New polotno update has provided us with new features which can be paired with this. 
Kindly visit https://polotno.com/docs/text-overflow  for new features that can be used*/
unstable_setTextVerticalResizeEnabled(true)

/*Out of the box, Polotno does not support the rendering of rich text elements, meaning you cannot apply different styles, 
colors, fonts, etc., to parts of the text. The entire text element must maintain a uniform style. 
However, Polotno offers experimental support for rich text elements, which must be explicitly activated.*/
unstable_useHtmlTextRender(true)

const store = createStore({
  key: `${POLOTNO_KEY}`,
  showCredit: false,
})

const getVideoStyle = (tone: string, script: any): string => {
  if (tone === 'Professional'){
    if (!script.data[0].searchQueries) {
      return 'Burst'
    }
    return 'Simple'
  }
  if (tone === 'Upbeat') {
    if (script.data[0].images) {
      return 'Burst'
    }
    return 'Simple'
  }
  return 'Simple'
}

// Remove sections that are not needed in the left side panel.
const EmptySection = {
  name: 'videos',
  Tab: () => null,
  Panel: () => null,
}
const sectionsToRemove = ['text', 'templates', 'size', 'elements', 'background', 'photos', 'size']
const sections = [CustomVideoTimelineSection as any, EmptySection as any]
if (IS_FREE_PLATFORM === 'false') {
  sectionsToRemove.push('upload')
  // sections.push(CustomAssetsSection as any)
  sections.push(customVideoVariations as any)
}
sections.push(...DEFAULT_SECTIONS.filter((section) => !sectionsToRemove.includes(section.name)))

/* loading the initial json from the project and loading it into the store
Load passed json into the store. It will update all properties, pages and elements. 
By default loading JSON into the store will reset undo/redo history (so a user can't undo it). 
You can use keepHistory argument, if you want to preserve the history */
const LoadInitialJson = async (store: any, json: any) => {
  if (json) {
    await store.loadJSON(json, false)
  }
}

type DecodedData = {
  token: string
  email: string
  name: string
  status: string
  createdAt: string
  updatedAt: string
}

// Decodes a URI-encoded cookie value and parses it into a JSON object
export const decodeAndParseCookie = (cookieValue: any): DecodedData => {
  const decodedString = decodeURIComponent(cookieValue)
  const data: DecodedData = JSON.parse(decodedString)
  return data
}

export const App = () => {
  const { data: domainData } = useGetDomainDetails();
  const projectID = location.pathname.split('/').pop()
  const [, setPageLoading] = useState(true)
  const [, setFirstRender] = useState(false)
  const [open, setOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [bulkCreatePropsArray, setBulkCreatePropsArray] = useState<any[]>([])
  const sidebarRef = useRef<HTMLDivElement>(null);
  // const [progressBarPercent, setProgressBarPercent] = useState<number>(0)

  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [isLoading, setIsLoading],
    RegeneratedScriptDetails: [, setRegeneratedScriptDetails],
    VideoStyle: [, setVideoStyle],
    UndoStack: [, setUndoStack],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
    VideoMediaType: [, setVideoMediaType],
    ProgressPercent: [progressBarPercent, setProgressBarPercent],
    RightSideBar: [activeComponent, setActiveComponent],
    CurrentUserData: [currentUserData, setCurrentUserData],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    RemainingTasks: [remainingTasks, setRemainingTasks],
  } = useGlobalContext()

  const { data: projectData, error: projectError } = useGetProject(projectID)
  const { data: userData, error: userError } = useGetUserDeails(localStorage.getItem('accessToken'))
  //***white labeling **//
  useEffect(() => {
    if (userData) setCurrentUserData(userData)
  }, [userData])
  useEffect(() => {
    const changeFavicon = (url: string) => {
      let link = document.querySelector('link[rel*="icon"]') as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement;
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = url;
    };

    changeFavicon(domainData?.fav_icon_url);
    document.title = (domainData?.brand_name?.charAt(0)?.toUpperCase() + domainData?.brand_name?.slice(1)) || ' -- ';

    if (domainData) {
      if (domainData?.domain_name?.includes('quickads')) {
        const element = document.querySelector('.editor-container .polotno-side-tabs-container') as HTMLDivElement;
        if (element) {
          const styleElement = document.createElement('style');
          styleElement.innerHTML = `
            .editor-container .polotno-side-tabs-container {
              background-image: url("https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/editor_sidebar_bg.png") !important;
            }
          `;
          document.head.appendChild(styleElement);
        }
      } else {
        // const shades = generatePrimaryShades('#008000');
        const shades = generatePrimaryShades(domainData?.colors?.primary || '#7A5AF8');
        const root = document.documentElement;
        root.style.setProperty('--color-primary-50', shades[50]);
        root.style.setProperty('--color-primary-100', shades[100]);
        root.style.setProperty('--color-primary-200', shades[200]);
        root.style.setProperty('--color-primary-300', shades[300]);
        root.style.setProperty('--color-primary-400', shades[400]);
        root.style.setProperty('--color-primary-500', shades[500]);
        root.style.setProperty('--color-primary-600', shades[600]);
        root.style.setProperty('--color-primary-700', shades[700]);
        root.style.setProperty('--color-primary-800', shades[800]);
        root.style.setProperty('--color-primary-900', shades[900]);
        root.style.setProperty('--color-gradStart', shades[300]);
        root.style.setProperty('--color-gradEnd', shades[500]);

      }
    }
  }, [domainData]);


  // ****** Resize on first load hook function ****** //
  async function resizeOnFirstLoad(projectDataProp: any, userDetails: any, sizeString?: string) {
    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    await resizeDesign(oldJSON, store, userDetails, setUserDetails, true, projectDataProp, sizeString)
  }
  const specificContainerRef = useRef<HTMLDivElement>(null);
  const handleToggleComponent = (componentName: string) => {
    if (activeComponent === componentName) {
      setActiveComponent('')
    } else {
      setActiveComponent(componentName)
    }
  }
  const handleClickOutside = (event: MouseEvent) => {
    // if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
    //   // setIsVisible(false);
    //   // handleToggleComponent(activeComponent)
    //   return
    // }

    if (specificContainerRef.current && specificContainerRef.current.contains(event.target as Node)) {
      // If click is inside specific container, call the function
      handleToggleComponent(activeComponent);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // Handles errors from project data fetching based on HTTP status codes and manages redirection or authentication cleanup
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      if (projectError && axios.isAxiosError(projectError)) {
        const axiosError = projectError as AxiosError
        message.error('Error fetching project data: ' + axiosError.message)

        const statusCode = axiosError.response ? axiosError.response.status : null

        if (statusCode === 400 || statusCode === 404) {
          document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          localStorage.removeItem('accessToken')
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout();
            // window.location.href = QUICKADS_REDIRECT_URL
          }
        }

        if (statusCode === 403) {
          document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          localStorage.removeItem('accessToken')
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout();
            // window.location.href = QUICKADS_REDIRECT_URL
          }
        }

        if (statusCode === 406) {
          document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          localStorage.removeItem('accessToken')
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') {
            domainLogout();
            // window.location.href = QUICKADS_REDIRECT_URL
          }
        }
      }
    }
  }, [projectError])

  // Set initial states and generate video once projectData response is recieved
  useEffect(() => {
   async function getData(){
    if (projectData && IS_FREE_PLATFORM === 'false') {
      const jsonDataRes = projectData?.json_body
      const videoStyle = getVideoStyle(projectData?.purpose?.tone, projectData?.script) || 'Simple'
      localStorage.setItem('style', videoStyle);
      localStorage.setItem('Prompt', projectData?.purpose?.promptToVideo);
      localStorage.setItem('graphicalStyle', projectData?.purpose?.graphicalStyle);

      const videoMediaType = projectData?.purpose?.mediaType || 'Images'
      LoadInitialJson(store, jsonDataRes)
      setUserDetails(projectData)
      // setScriptDetails(testScriptresponse)
      setScriptDetails(projectData?.script)
      setRegeneratedScriptDetails(projectData?.script?.data)
      setFirstRender(projectData?.first_render)
      setVideoStyle(videoStyle)
      setUndoStack([projectData?.script])
      setIsStoreUpdated(!isStoreUpdated)
      setVideoMediaType(videoMediaType)
      localStorage.setItem('aspect_ratio', projectData?.purpose?.size)
      if (projectData?.first_render) {
        ScriptToCanvasFunc(
          setPageLoading,
          projectData?.script,
          projectData?.first_render,
          projectData?.purpose?.industry === undefined || projectData?.purpose?.industry === 'undefined' ? 'Others' : projectData?.purpose?.industry,
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          projectData?.logo,
          projectData?.purpose?.size,
          videoStyle,
          videoMediaType,
          store,
          projectData?.colors?.secondary || '',
          projectData,
          setShowTextOverlay
        ).then(() => {
          resizeOnFirstLoad(projectData, userDetails, projectData?.purpose?.size).then(() => {
            setIsLoading(false)
            setPreviewOpen(true)
          })
        })
      } else {
        // console.log(projectData?.script)
        if (projectData?.script?.backgroundMusic?.url.includes('typeframes')) {
          const backgroundScript = {
            url: 'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav',
            duration: 30,
            industry: "Others",
            name: 'Deep Dream',
            backgroundVolume: 0.1,
          }
          await setScriptDetails((prevScript: { data: any }) => {
            const updatedScript = { ...prevScript }
            return { ...updatedScript, backgroundMusic: backgroundScript }
          })
         
        } else {
          console.log('The URL does not contain "typeframe".');
        }
     await updateWaterMarkVideos(store)

        resizeOnFirstLoad(projectData, userDetails, projectData?.purpose?.size).then(() => {
          setIsLoading(false)
          setPreviewOpen(true)
        })

      }
     
      store.history.clear()
    //  console.log(store.toJSON())

    }
    }
    getData()
  }, [projectData])

  // Set initial states and generate video for the free version
  useEffect(() => {

    if (IS_FREE_PLATFORM === 'false') {
      const token = getCookie('crossToken')

      if (token) {
        const data = decodeAndParseCookie(token)
        localStorage.setItem('userData', JSON.stringify(data))
      }

    } else {
      const script = getCookie('scriptData')
      const size = getCookie('projectSize')
      const industry = getCookie('projectIndustry')
      const style = getCookie('projectStyle')
      if (script) {
        const data = decodeAndParseCookie(script)
        const scriptData = { data: JSON.parse(script) }
        localStorage.setItem('scriptData', JSON.stringify(data))
        setScriptDetails(scriptData)
        setRegeneratedScriptDetails(scriptData)
        setVideoStyle(style ? style.toString() : 'Simple')
        ScriptToCanvasFunc(
          setPageLoading,
          scriptData,
          true,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          '',
          size ? size.toString() : '9:16',
          style ? style.toString() : 'Simple',
          'Images',
          store,
          '',
          projectData,
          setShowTextOverlay
        ).then(() => {
          resizeOnFirstLoad(projectData, userDetails, size ? size.toString() : '9:16').then(() => {
            setIsLoading(false)
            setPreviewOpen(true)
          })
        })
      }
    }
  }, [])

  // Call handlePutImage once 10 seconds after the page loads to save preview of the project
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      const timer = setTimeout(() => {
        handlePutImage(projectID, store)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [isLoading])

  useEffect(() => {
    const timer = setTimeout(() => {
      trackEvent('Free Video Ad Generated')
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  // auto save project and handlePutImage every 20 seconds
  // useEffect(() => {
  //   if (IS_FREE_PLATFORM === 'false') {

  //     const interval = setInterval(() => {

  //       handlePutImage(projectID, store)
  //       updateProject(userDetails, store, scriptDetails)
  //     }, 20000)
  //     return () => clearInterval(interval)
  //   }
  // }, [])
  useEffect(() => {
    const saveData = () => {
      if (!userDetails?.first_render) {
        handlePutImage(projectID, store);
        updateProject(userDetails, store, scriptDetails);
      }
    };

    // Run saveData every 10 seconds
    const intervalId = setInterval(saveData, 10000); // 10000 ms = 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [userDetails, store, scriptDetails]);

  useEffect(() => {
    if (progressBarPercent >= 90) {
      handlePutImage(projectID, store)
      updateProject(userDetails, store, scriptDetails)

      if (userData?.voice_limit == 1) {
        creditsDeduct('voice_credits', 1)
        return
      }
      // const totalDurationInSeconds = scriptDetails.data.reduce((sum:number, item:any) => sum + item?.sounds?[0]?.audioDuration || 0, 0);
      const totalDurationInSeconds = scriptDetails.data.reduce((sum: number, item: any) => {
        return sum + (item.sounds && item.sounds.length > 0 ? item.sounds[0].audioDuration : 0);
      }, 0);
      const audioDurationInMins = Math.ceil(totalDurationInSeconds / 60)
      creditsDeduct('voice_credits', audioDurationInMins)
      const gettyIds = getCookie('gettyIds');
      // if (gettyIds !== undefined) {
      //   console.log(JSON.parse(gettyIds))
      //   // try {
      //   //   gettyAssetTrack(gettyIds, 1)
      //   // } catch (error) {
      //   //   console.log(error)
      //   // }
      // }
    }
  }, [progressBarPercent])

 

  return (
    <>
      {domainData && <>
        {isMobile && <IsMobileModal />}
        {isLoading && <Loading percentage={progressBarPercent} />}
        <PageLayout heading={'Social Media Post Generator'} domainData={domainData}>
          <div onContextMenu={(e) => e.preventDefault()}>

            <PolotnoContainer style={{ width: '100%', height: '93vh' }}>
              <SidePanelWrap>
                <SidePanel store={store} sections={sections} defaultSection='video-timeline' />
              </SidePanelWrap>
              <WorkspaceWrap>
                <Toolbar
                  store={store}
                  components={{
                    History: () => <CustomHistoryButtons store={store} />,
                    ActionControls: () => (
                      <>
                        <CustomActionControlContainer
                          store={store}
                          open={open}
                          setOpen={setOpen}
                          bulkCreatePropsArray={bulkCreatePropsArray}
                          setBulkCreatePropsArray={setBulkCreatePropsArray}
                          previewOpen={previewOpen}
                          setPreviewOpen={setPreviewOpen}
                          setProgressBarPercentage={setProgressBarPercent}
                        />
                      </>
                    ),
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'row',
                    background: 'transparent',
                  }}
                >
                  <div style={{ display: 'flex', width: '94%', flexDirection: 'column', background: 'white' }} ref={specificContainerRef}>
                  
                   <ResizeDropdown store={store} />
                   <AnimationDropdown store={store} />
                  
                   

                    {/* <div ref={specificContainerRef}> */}
                    <Workspace
                      store={store}
                      pageBorderColor='white'
                      activePageBorderColor='#7735BD'
                      backgroundColor='white'
                      components={{ PageControls }}

                    />
                    {/* </div> */}

                    <ZoomButtons store={store} />
                    <PagesTimeline store={store} defaultOpened />
                  </div>
                  <div className='right-side-bar' ref={sidebarRef}>
                    <RightSidebar store={store} />
                  </div>
                </div>
              </WorkspaceWrap>
            </PolotnoContainer>
          </div>
        </PageLayout>
      </>}
    </>
  )
}
